import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { CredentialResponse } from "@react-oauth/google";
import React,{RefObject} from "react"
import Carousel from 'react-elastic-carousel';
const uuId=require('device-uuid')

interface CustomRefType extends RefObject<Carousel> {
  goTo: (val: number) => void;
  slideNext: ()=>void;
  slidePrev: ()=>void;
}
export interface IAttributes {
  id: number;
  full_name: string;
  user_name: string;
  email: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string;
  gender: string | null;
  profile_photo: string | null;
  cover_photo: string | null;
}

export interface IMetaToken {
  token: string;
  refresh_token: string;
}

export interface IResponse {
  data: {
    id: string;
    type: string;
    attributes: IAttributes;
  };
  meta: IMetaToken;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?:boolean
  classes?:{[key:string]:string}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  lightModeSplashScreen: boolean;
  splashScreenPageLoading: boolean;
  carouselActivePage: number;
  open:boolean;
  error:string;
  apiError:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  googleAccountApiCallId:string=''
  loggedInApiCallId:string=''
  checkUserLoginApi:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      lightModeSplashScreen: false,
      splashScreenPageLoading: true,
      carouselActivePage: 0,
      open:false,
      error:'',
      apiError:''
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.carouselRef = React.createRef<CustomRefType>();
    this.handleClick = this.handleClick.bind(this)
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.getProfileGoogleSignin(message)
    this.getUserApi(message)
    this.redirectLoggedInuser(message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token=await getStorageData('authToken') 
    if(token){
      this.handleUserLogin(token)
    }
    else{
      this.setState({splashScreenPageLoading:false})
    }
    // Customizable Area End
  }

  // Customizable Area Start

  carouselRef: React.RefObject<CustomRefType>;

handleClick=(event:{key:string})=>{
    if (event.key === 'ArrowUp') {
     //@ts-ignore
     this.carouselRef.slidePrev()
       
    }
    if(event.key === "ArrowDown"){
     //@ts-ignore
     this.carouselRef.slideNext()
    }
   }

  handleUserLogin=async(token:string)=>{
    
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token:token,
    };
    const getUserMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkUserLoginApi = getUserMsg.messageId;

    getUserMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedInEndPoint
    );

    getUserMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getUserMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getUserMsg.id, getUserMsg); 
    
  }
  
  redirectLoggedInuser=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({splashScreenPageLoading:false})

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.checkUserLoginApi) {
          const status=responseJson.data.attributes.signup_state
          if(status==='completed'){
            this.navigateToRoute("LandingPage")
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        this.setState({ apiError: responseJson.errors[0] })
      }
    }
  }

  goToLoginPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToSignUpPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleOpenModal=()=>{
    this.setState({
      open:true
    })
  }
  handleClose=()=>{
    this.setState({
      open:false
    })
  }

  handleApiRespOfLogin = (responseJson:any)=>{
    setStorageData('authToken',responseJson.meta.token)
    this.navigateToScreens(responseJson.data.attributes.signup_state)
    this.handleMultiUserResponse(responseJson);
  }

  navigateToScreens=(route:string)=>{
    switch (route) {
      case 'date_of_birth':
        this.navigateToRoute("DateOfBirth");
        break;
      case 'user_name':
          this.navigateToRoute("OTPInputAuth", 'user');
          break;
  
      case 'choose_profile':
        this.navigateToRoute("OTPInputAuth",'profile');
        break;
  
      case 'select_category':
          this.navigateToRoute("Categories");
        break;
  
      case 'select_sub_category':
        this.navigateToRoute("Categories",'sub');
        break;
  
      case 'choose_subscription':
          this.navigateToRoute("Customisableusersubscriptions");
          break;
  
      case 'become_an_owner':
          this.navigateToRoute("BecomeOwner");
        break;
  
      case 'connect_social_account':
        this.navigateToRoute("Followers");
        break;
  
      case 'follow_account':
          this.navigateToRoute("Followers",'follow');
          break;
      case 'completed':
            this.navigateToRoute("LandingPage");
          break;    
  
      default:
        break;
    }
  }

  navigateToRoute=(route:string,profile?:string)=>{
    const messageSend: Message = new Message(getName(MessageEnum.NavigationMessage));
    messageSend.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messageSend.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    const raiseMessageSend: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessageSend.addData(getName(MessageEnum.SessionResponseData), {
      active:profile,
    });
    messageSend.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessageSend);
    this.send(messageSend);
  }

  getProfileGoogleSignin=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.googleAccountApiCallId) {
          this.handleApiRespOfLogin(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);

        }
      } else {
        this.setState({ apiError: responseJson.errors[0]})
      }
    }

  }


  getLoggedInUser=(responseJson:IResponse)=>{
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token: responseJson.meta.token,
    };

    const getUserMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loggedInApiCallId = getUserMsg.messageId;

    getUserMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedInEndPoint
    );

    getUserMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getUserMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getUserMsg.id, getUserMsg);

  }

  getUserApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.loggedInApiCallId) {
          const userObject: {
            profilePhoto: string | null,
            followingCount: number,
            followersCount: number, userSubscription: string,
            userOwnership: boolean, categories: boolean,
            subCategories: boolean,
            dateOfBirth:any,
          } = {
            profilePhoto: responseJson.data.attributes.profile_photo,
            followingCount: responseJson.data.attributes.following_count,
            followersCount: responseJson.data.attributes.followers_count,
            userSubscription: responseJson.data.attributes.user_subscription,
            userOwnership: responseJson.data.attributes.user_ownership,
            categories: responseJson.data.attributes.user_categories,
            subCategories: responseJson.data.attributes.user_sub_categories,
            dateOfBirth:responseJson.data.attributes.date_of_birth,
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        this.setState({ apiError: responseJson.errors[0] })
      }
    }


  }


  navigateToUserName=(responseJson:IResponse)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OTPInputAuth"
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {screen:3,response:responseJson});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleMultiUserResponse = async (responseJson: IResponse) => {
    let setMultiUser: Array<{
      refresh_token: string | undefined;
      user_id: string;
      token: string | undefined;
      profile_photo: string;
      username: string;
      fullName: string;
    }> = [];
    const payloadDetails = {
      refresh_token: responseJson?.meta && responseJson?.meta?.refresh_token,
      user_id: responseJson.data.id,
      token: responseJson?.meta && responseJson?.meta?.token,
      profile_photo: responseJson.data.attributes.profile_photo as string,
      username: responseJson.data.attributes.user_name,
      fullName: responseJson.data.attributes.full_name,
    };
    setStorageData("selectedUser", JSON.stringify(payloadDetails));
    const userDetails = await getStorageData("multi_user", true);

    setMultiUser = userDetails ?? [];
    if (setMultiUser.length > 0) {
      const findUser = setMultiUser.find(
        (item) => item.user_id === payloadDetails.user_id
      );
      if (findUser) {
        const multi_user = setMultiUser.map((item) => {
          if (item.user_id === payloadDetails.user_id) {
            return {...item,username:payloadDetails.username,profile_photo:payloadDetails.username};
          } else {
            return item;
          }
        });
        setStorageData("multi_user", JSON.stringify(multi_user));
      } else {
        setMultiUser.push(payloadDetails);
        setStorageData("multi_user", JSON.stringify(setMultiUser));
      }
    } else {
      setMultiUser.push(payloadDetails);
      setStorageData("multi_user", JSON.stringify(setMultiUser));
    }
  };

  signinUserWithGoogle=(token:string)=>{
    const deviceUniqueId=new uuId.DeviceUUID().get()
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const apiDetails = {
     attributes:{id_token:token,platform:"google",is_early_adopter:true,device_id:deviceUniqueId}
    };
  
    const httpBody = {
      data: apiDetails,
    };
  
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.googleAccountApiCallId = requestMessages.messageId;
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleAuthEndPoint
    );
  
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessages.id, requestMessages);
  
  }
  
  navigateToUserNameScreen=(responseJson:IResponse)=>{
    const response: Message = new Message(getName(MessageEnum.NavigationMessage));
    response.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    response.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OTPInputAuth"
    );
    const raiseResponse: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseResponse.addData(getName(MessageEnum.SessionResponseData), {screen:3,response:responseJson});
    response.addData(getName(MessageEnum.NavigationRaiseMessage), raiseResponse);
    this.send(response);
  }
  
  handleLoginSuccess = (credentialResponse:CredentialResponse) => {
    const token=credentialResponse?.credential||''
    this.signinUserWithGoogle(token)
  };
  handleAppleLoginSuccess = (credentialResponse:any) => {
    console.log("apple accounts login resp:", credentialResponse); 
    window.localStorage.setItem("IsLoggedinFromApple", "true")
    const updatedResp = {
      ...credentialResponse,
      meta: {
        token: credentialResponse?.meta?.tokens?.login, // Correctly accesses the login token
        refresh_token: credentialResponse?.meta?.tokens?.refresh, // Correctly accesses the refresh token
      },
    };
    console.log("updated resp",updatedResp);
    
    this.handleApiRespOfLogin(updatedResp)
  };
  
  handleLoginError = () => {
   this.setState({error:'Login error'})
  };


  // Customizable Area End
}
