import React from "react";

// Customizable Area Start
import {
  Box,
  Switch,
} from "@material-ui/core";
import { 
  createTheme,
   styled,
   ThemeProvider,
    withStyles 
  } from "@material-ui/core/styles";
import NavigationMenu from '../../navigationmenu/src/NavigationMenu.web';
import RightSideBar from "../../activityfeed/src/RightSideBar.web";
import { 
  ArrowIcon,
  darkArrowIcon
   } from "./assets";
import {
  UserSubscription
} from "./AutomaticRenewalsController";

// Customizable Area End

import AutomaticRenewalsController, {
  Props,
  configJSON,
} from "./AutomaticRenewalsController";

export default class AutomaticRenewals extends AutomaticRenewalsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   conditionShort = (condition:boolean,trueStatement:any,falseStatement:any) =>{
      return condition ?  trueStatement :falseStatement
     }
  renderSubscriptionDetail = (item:UserSubscription) =>{
    const {subscription_plan,powered_by,expires_on,auto_renewal} = item.attributes
    return(
      <Box className="userCard" style={{backgroundColor:`${this.conditionShort(this.props.checked,"#E9E9E9","#222222")}`}}>
      <div>
      <div className="cardHeader">
        <span className="title" >{subscription_plan.title}</span>
        <span className="price">${subscription_plan.price}/{subscription_plan.period}</span>
      </div> 
      <span className="subTitle">(Powered by {powered_by})</span> 
      </div>
      <div className="description">
      <span className="subTitle">Plan Duration: {subscription_plan.period}</span> 
      <span className="subTitle">Expires on {this.formatDate(new Date(expires_on))}</span>
      </div>
      <div className="cardHeader">
        <span className="automaticRenewals">Automatic renewals</span>
        <IOSSwitch data-test-id='switch' onChange={(event) => {this.updateAuotRenewal(event,item.id)}} checked={auto_renewal} />
      </div> 
     </Box>
             
    )
   }
  // Customizable Area End

  render() {
    // Customizable Area Start
     
    return (
      <ThemeProvider theme={ this.conditionShort(this.props.checked , commonLightTheme,commonDarkTheme)}>
        <NavigationMenu id='' navigation={this.props.navigation} checked={this.props.checked}>
          <BoxContainer>
            <Box className='centerPortionWithTopNavition'>
                <Box className='TopNavigation'>
                  <Box><img className='topIcon' onClick={()=>{}} src={this.conditionShort(this.props.checked, darkArrowIcon,ArrowIcon)} /></Box>
                   <span className="label">My Subscription</span>
                </Box>
              <Box className="subscriptionContainer">
                <span className="subscriptionSubHeader">Platform Subscription</span>
                
              </Box>
              <Box className="subscriptionContainer">
                <span className="subscriptionSubHeader">Users Subscription</span>
                {this.state.subscriptionsList.map((item) => (
                    <>{this.renderSubscriptionDetail(item)}</>
                ))}
               
              
              </Box>
            </Box>
            <RightSideBar checked={this.props.checked} />
          </BoxContainer>
        </NavigationMenu>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxContainer = styled(Box)({
  display:"flex",
  width: "100%",
  paddingTop:12,
  "& .centerPortionWithTopNavition":{
      display:'flex',
      width:'60%',
      flexDirection:'column',
      height:"95vh",
      overflow:"scroll",
      scrollbarWidth:"none",
      maxWidth:'810px',
      "@media (max-width:1000px)": {
        width:'100%'
       }
    },

    "& .TopNavigation":{
      display:'flex',
      flexDirection:'row',
      top:1,
      zIndex:1000,
      maxWidth:" 810px",
      height:84,
      padding:"0px 20px",
      gap:16,
      alignItems:'center',
      "& .topIcon":{
        padding:"10px 12px",
       "&:hover":{
        opacity:0.4,
        background:'grey'
       }
      },
      "& .label":{
        fontFamily: "Manrope",
        fontSize: 24,
        fontWeight: "800",
        lineHeight: "27.12px",
        letterSpacing: "-0.02em",
        textAlign: "left",
      }
    },
    "& .subscriptionContainer":{
      padding:"28px 24px",
      display:"flex",
      flexDirection:"column",
      gap:16,
      "& .subscriptionSubHeader":{
        fontFamily: "Manrope",
        fontSize: 16,
        fontWeight: "600",
        lineHeight: "19.2px",
      },
      "& .platformCard":{
        width:"90%",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        borderRadius:10,
        border:"1px solid #6B6B6B",
        padding:12,
        gap:20,
        display:"flex",
        flexDirection:"column"
      },
      "& .userCard":{
        width:"90%",
        border:"1px solid #6B6B6B",
        borderRadius:10,
        padding:12,
        gap:20,
        display:"flex",
        flexDirection:"column"
      }
    },
    "& .cardHeader":{
      display:"flex",
      alignItems:"center",
      justifyContent:"space-between",
    },
    "& .title":{
      fontFamily: "Manrope",
      fontSize: 18,
      fontWeight: "800",
      lineHeight: "21.6px",
    },
    "& .price":{
      fontFamily: "Manrope",
      fontSize: 14,
      fontWeight: "500",
      lineHeight: "21px",
    },
    "& .subTitle":{
      fontFamily: "Manrope",
      fontSize: 10,
      fontWeight: "500",
      lineHeight: "12px",
    },
    "& .description":{
      display:"flex",
      flexDirection:"column",
      gap:5
    },
    "& .automaticRenewals":{
      fontFamily: "Manrope",
      fontSize: 16,
      fontWeight: "600",
      lineHeight: "19.2px",
    },
    

})


const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    },
    info: {
      main: "#222222",
    },
    warning: {
      main: "#78716C",
    },
   }
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
    }
  
  });
  
  
const commonDarkTheme=createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#3858E3",
      },
      secondary:{
      main:"rgba(255,255,255,0.75)"
      },
      info: {
        main: "#FFFFFF",
      },
      warning: {
        main: "#78716C",
      },
     }
    });

    const IOSSwitch = withStyles(({
      root: {
        width: 48,
        height: 28,
        padding: 0,
      },
    
      switchBase: {
        padding: 0,
        transform: "translate(4px,4.4px)",
        "&$checked": {
          transform: "translate(23px,4.4px)",
          color: theme.palette.common.white,
          "& + $track": {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            opacity: 1,
            border: "none",
          },
        },
        
      },
      thumb: {
        width: 24,
        height: 24,
        margin:"-2px -1px"
      },
      track: {
        borderRadius: 26 / 2,
        backgroundColor: "#1F1F1F",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
      },
      checked: {},
      focusVisible: {},
    }))(Switch);  
// Customizable Area End
