
export const arrowLogo = require("../assets/arrowlogo.png");
export const arrowLogoSvg = require("../assets/arrowLogoSvg.svg");
export const dashboardImage = require("../assets/dashboardLight.png");
export const drakThemeImage = require("../assets/dashboardDark.png");
export const profile=require('../assets/profile.png');
export const verifyIcon=require('../assets/verify.svg');
export const verifyOrange = require("../assets/verifyOrange.svg");
export const verifyYellow = require("../assets/verifyYellow.svg");
export const ownerShipIcon = require("../assets/ownerShipIcon.svg");
export const defualtUserAvatar = require("../assets/user_1.png");