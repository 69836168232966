export const icAppleIcon = require("../assets/ic_apple_icon.png");
export const icAppleIconBlack = require("../assets/ic_apple_icon_black1.png");
export const icAppleIconWhite = require("../assets/ic_apple_icon_white.png");
export const icAppleIconWhiteBorder = require("../assets/ic_apple_icon_black_white_border.png");
export const appleLogo = require("../assets/appleLogo.svg");
export const appleDarkLogo = require("../assets/appleDark.svg");
export const lightThemeBg=require('../assets/lightBg.png')
export const darkThemeBg=require('../assets/darkBg.png')
export const next=require('../assets/next.svg')
export const prev=require('../assets/prev.svg')
export const RightIcon=require('../assets/RightIcon.svg')
export const leftArrow=require('../assets/leftArrow.svg')
