// Customizable Area Start
import React from "react";
import {      AppBar,
  Box,
  Backdrop,
  Button,
  Modal,
  IconButton,
  Typography,
  styled,
  List, ListItem,
  Fade,
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {ArrowBackIcons,  ArrowColorIcon, ArrowDown} from "./assets"
import RightSideBar from "../../activityfeed/src/RightSideBar.web";
import CloseIcon from '@material-ui/icons/Close';
import { profileLogo} from "../../activityfeed/src/assests"
import ContentModerationController, {
  configJSON,
} from "./ContentModerationController";
import PostBody from "../../activityfeed/src/PostBody.web";
import { IUserProfileAttributes } from "../../customisableuserprofiles/src/ProfileController.web";

const inputStyles = {
  fade: {
    border: '1px solid #5B5B5B',
    borderRadius: '10px',
  },
  lightBackdrop: {
    backgroundColor: '#FFFFFF',
    opacity: 0.9
  },
  darkBackdrop: {
    backgroundColor: '#0E0E0E',
    opacity: 0.8
  }
}

class ContentModeration extends ContentModerationController {

  conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
    return condition? trueStatement: falseStatement
  }


  renderUser=()=>{
   const reported_account = this.state.reportData?.attributes.reported_account as IUserProfileAttributes
     
    return(
      <StyledCard data-testid="report_accounts">
          <Box display="flex" alignItems="center" style={webStyles.boxStylePadding}>
          <img
              src={reported_account?.profile_photo  || profileLogo}
              alt="profile-photo"
              style={webStyles.postImage}
          />
            <Box style={webStyles.boxStyleMarginDiff}>
              <Box style={webStyles.boxStyle}>
                <NameText>{reported_account?.full_name}</NameText>
                 {this.renderVerifyIcon(reported_account)} {this.renderOwnershipIcon(reported_account)}
              </Box>
              <TextStyle>{reported_account?.user_name}</TextStyle>
            </Box>
            <ProfileButton data-testid="viewButton" onClick={()=>this.handleNavigation(reported_account.id)} variant="contained">{configJSON.view}</ProfileButton>
          </Box>
        </StyledCard>
    )
  }

  renderPost=()=>{
    if(this.state.reportData){
      
      const post = {
        id:this.state.reportData?.attributes.id.toString(),
        type:'post',
        attributes:this.state.reportData?.attributes.post
      }

      const {
        post_by,
        is_repost,
        parent_post,
        body,} = post.attributes
      const author = is_repost && parent_post?.post_by && !body ? parent_post?.post_by : post_by
      return(
        <StyledCard data-testid="report_post">
        <Box display="flex"  style={webStyles.boxStylePadding}>
        <img
          src={author.profile_photo || profileLogo}
          alt="profile-photo"
          style={webStyles.postImage}
        />
          <Box style={webStyles.boxStyleMargin}>
            <Box style={webStyles.boxStyle}>
              <SubText>{author.full_name}</SubText>
               {this.renderVerifyIcon(author)} {this.renderOwnershipIcon(author)}
              <TextStyle>{author.user_name}</TextStyle>
            </Box>
            <TextStyle style={webStyles.textStyleMargin}>{post.attributes.created_at}</TextStyle>
          </Box>
          <ProfileButton data-testid="viewButton"  onClick={()=>this.handleNavigation(post.attributes.id)} style={webStyles.profileMargin} variant="contained">{configJSON.view}</ProfileButton>
        </Box>
        <Box>
          {this.state.reportData && <PostBody 
             postData={post}
             checked={this.props.checked}
           />}
        </Box>
      </StyledCard>
      )
    }
  }

  render() {
    const {  checked } = this.props
    const modalStyles = {
      backgroundColor: this.conditionRemover(checked , '#fff' , 'rgba(11, 11, 11, 1)'),
      color: this.conditionRemover(checked , '#000' , '#fff'),
    };

    const customListStyle =  { display: 'flex', color: modalStyles.color }

    const modalWebStyle = this.props.checked ? inputStyles.lightBackdrop : inputStyles.darkBackdrop

    const backgroundStyle={
      backgroundColor: this.conditionRemover(checked , '#fff' , 'rgba(11, 11, 11, 1)'),
    }
  
   const colorStyle={
    color: this.conditionRemover(checked , '#000' , '#fff'),
   }

    return (
      <NavigationMenu tabItem="Alerts" id="" checked={this.props.checked} navigation={this.props.navigation}>
      <WrapperBox>
        {this.state.reportData &&
        <StyledContainer>
          <StyledAppBar position="static">
            <StyledIconButton color="inherit" data-testid="goBack" onClick={this.props.navigation.goBack}>
              <img src={ArrowBackIcons} alt="Back" />
            </StyledIconButton>
            <HeadingText  style={colorStyle}>
              {configJSON.reportDetailsHeading}
              
            </HeadingText>
          </StyledAppBar>

          <StyledCard>
              <SubtitleText>{configJSON.report} {this.state.reportData.attributes.created_at}</SubtitleText>
              <SubText style={webStyles.subtext}>
                 {this.state.content}
              </SubText>

              <ViewRuleButton  data-testid="viewRule" onClick={this.openMainModal}>{configJSON.viewRule}</ViewRuleButton>
          </StyledCard>
          <div>
      {this.state.userPostState ? (
        this.renderUser()
      ) : this.renderPost()}
       </div>
          <StyledCard>
              <SubText >{configJSON.whatsNext}</SubText>
              <DescriptionText>
                {configJSON.descriptionText}
              </DescriptionText>

              <SubText style={webStyles.profileMargin}>{configJSON.whatsProcess}</SubText>
              <DescriptionText>
               {configJSON.whatsProcessDescriptionText}
              </DescriptionText>
              <DescriptionText>
              {configJSON.reportContentLabel1} <br />
              {configJSON.reportContentLabel2} <br />
              {configJSON.reportContentLabel3} <br />
              {configJSON.reportContentDescription}
              </DescriptionText>
          </StyledCard>
        </StyledContainer>

        }

              <Modal
              open={this.state.isMainModalOpen}
              onClose={this.closeMainModal}
              data-testid="report_issue"
              aria-labelledby="rule-modal-title"
              BackdropComponent={Backdrop}
              BackdropProps={{
                style: modalWebStyle
              }}
            >
              <Fade 
               in={this.state.isMainModalOpen}
               style={inputStyles.fade}>
              <ModalContent style={backgroundStyle}>
           <Header>
            <SubText style={colorStyle}>
              {configJSON.reportRule}
            </SubText>
            <IconButton onClick={this.closeMainModal} style={webStyles.iconStyle}>
              <CloseIcon  style={colorStyle}/>
              
            </IconButton>
          </Header>

          <ModalHeading style={colorStyle}>
            {configJSON.whatTypeReportRule}
          </ModalHeading>

          <List style={webStyles.listStylePadding}>
            {this.state.reportCategories.map((item, index) => (
                <CustomListItem key={index} button style={customListStyle}>
                <div>
                  <ModalText style={colorStyle}>{item.attributes.title}</ModalText>
                  <DescriptionText >{item.attributes.description}</DescriptionText>
                </div>
                <Box style={webStyles.profileMargin}>
                <IconButton data-testid="reportIssue" onClick={()=>this.openSecondaryModal(item)} style={webStyles.marginAuto}>
                <img src={ArrowColorIcon} alt="Back" />
                    </IconButton>

                </Box>

              </CustomListItem>
            ))}
          </List>
              </ModalContent>
              </Fade>
            </Modal>

            <Modal
          open={this.state.isSecondaryModalOpen}
          data-testid="report_sub_issue"
          onClose={this.closeSecondaryModal}
          aria-labelledby="secondary-modal-title"
        >
          <Fade in={this.state.isSecondaryModalOpen} style={inputStyles.fade}>
            <ModalContent style={backgroundStyle}>
            <Header>
            <SubText style={colorStyle}>
              {configJSON.reportRule}
            </SubText>
            <IconButton onClick={this.closeSecondaryModal} style={webStyles.iconStyle}>
              <CloseIcon  style={colorStyle}/>
              
            </IconButton>
          </Header>

          <ModalHeading style={colorStyle}>
            {configJSON.whatTypeReportRule}
          </ModalHeading>

          <List style={webStyles.listStylePadding}>
          <CustomListItem  button style={customListStyle}>
                <div>
                  <ModalText style={colorStyle}>{this.state.selectedReportIssue?.attributes.title}</ModalText>
                  <DescriptionText >{this.state.selectedReportIssue?.attributes.description}</DescriptionText>
                </div>
                <Box style={webStyles.profileMargin}>
                <IconButton data-testid="report_issue_2" onClick={()=>this.openMainModal()} style={webStyles.marginAuto}>
                <img src={ArrowDown} alt="Back" />
                    </IconButton>

                </Box>

              </CustomListItem>
            {this.state.subReportCategories&&this.state.subReportCategories.map((item, index) => (
                <CustomListItem key={index} button style={customListStyle}>
                <div>
                  <ModalText style={colorStyle}>{item.attributes.title}</ModalText>
                  <DescriptionText >{item.attributes.description}</DescriptionText>
                </div>
              </CustomListItem>

            ))}
          </List>
            </ModalContent>
          </Fade>
        </Modal>
          <RightSideBar checked={this.props.checked} />
      </WrapperBox>
    </NavigationMenu>
    );
  }
}

export default ContentModeration;

const webStyles :{ [key: string]: React.CSSProperties } ={
    iconStyle:{
        position: 'absolute' as 'absolute', 
        right: '16px' ,
        color: '#fff' 
      },
      postImage: {
        height: "35px",
        width: "35px",
        borderRadius: "50%",
        objectFit: "cover",
      },
    profileMargin:{
      marginTop:'10px'
    },
    marginAuto:{
       marginLeft: 'auto' 
    },
    boxTexColor:{
      color:'#6200EA'
    },
    boxPadding:{
      padding:'0px 0px 0px 57px'
    },
    textStyleMargin:{
      marginTop:'0px'
    },
    boxStyleMarginDiff:{
       marginLeft: "7px",
        marginTop: "3px"
    },
    boxStyle:{ 
      display: "flex",
      gap: "6px" 
    },
    boxStylePadding:{ 
      padding: "10px" 
    },
    boxStyleMargin:{ 
      marginLeft: "7px", 
    },
    listStylePadding:{
      padding:'20px'
    },
    subtext:{
      width:320,
      lineHeight:"21px"
    }
}

const Header = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '16px',
});
const ModalContent = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  outline: 'none',
  transform: 'translate(-50%, -50%)',
  width: 'clamp(300px, 90vw, 600px)', 
  padding: '10px 24px',
  borderRadius: '10px',
  border: '1px solid rgba(53, 56, 63, 1)',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  maxHeight: '80vh',
  overflowY: 'auto',
  scrollbarWidth: "none" as "none",

});
const CloseButton = styled(Button)({
  marginTop: '16px',
  backgroundColor: '#6200EA',
  color: 'white',
  textTransform: 'none'
});
const CustomListItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '1vh 0',
  color: '#fff',
  cursor: 'default',

  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiListItem-root': {
    gap: 0,
  },
});

const WrapperBox = styled(Box)({
  width: "100%",
  paddingTop: "20px",
  display: "flex",
  justifyContent: "center"
});

const StyledContainer = styled(Box)({
  display: "flex",
  width: "100%",
  maxWidth: "810px",
  flexDirection: "column",
  overflow:'auto',
  gap: "16px",
  padding: "0 20px",
  height: "calc(100vh - 20px)",
  "@media (max-width:1000px)": {
    width: "90%",
  },
  scrollbarWidth: "none" as "none",

});

const StyledAppBar = styled(AppBar)({
  display: 'flex',
  background: 'transparent',
  flexDirection:'row',
  boxShadow: 'none',
  alignItems: 'center',
  padding: '8px 0'
});
const HeadingText = styled(Typography)({
  fontSize:'24px',
  fontWeight:800,
  fontFamily: "Manrope",
});
const ModalHeading = styled(Typography)({
  fontSize:'31px',
  fontWeight:800,
  fontFamily: "Manrope",
  paddingLeft:'20px',
  paddingRight:'20px',
  color:'#FFFFFF'
});
const SubText = styled(Typography)({
  fontSize:'18px',
  fontWeight:800,
  fontFamily: "Manrope",
});
const ModalText = styled(Typography)({
  fontSize: 'clamp(1rem, 1.5vw, 1.25rem)',
  fontWeight:600,
  fontFamily: "Manrope",
  color:'#FFFFFF'
});

const NameText = styled(Typography)({
  fontSize:'12px',
  fontWeight:500,
  fontFamily: "Manrope",
});
const StyledCard = styled(Box)({
  padding: "10px 20px",
  borderRadius: "10px",
  border: '1px solid #222222',
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
});

const ViewRuleButton = styled(Box)({
  marginTop: "8px",
  color: '#6200EA',
  textTransform: "none",
  fontSize:'12px',
  fontWeight:800,
  fontFamily: "Manrope",
  cursor:'pointer'
});

const ProfileButton = styled(Button)({
  width:'65px',
  height:'24px',
  background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  Padding: "6px 20px 6px 20px",
  gap: "7px",
  borderRadius: "5px",
  display: "flex",
  color: "white",
  fontSize: "12px",
  fontFamily: "inter",
  fontWeight: 500,
  textTransform: "none" as "none",
  marginLeft:'auto'
});

const SubtitleText = styled(Typography)({
fontFamily: 'Manrope',
fontSize: '14px',
fontWeight: 500,
marginBottom:'8px',

});
const PostText = styled(Typography)({
  fontFamily: 'Manrope',
  fontSize: '14px',
  fontWeight: 500,
  
  });
const Badge = styled(Typography)({
  fontFamily: 'Manrope',
  fontSize: '10px',
  fontWeight: 500,
borderRadius:'6px',
padding:'3px 0px 3px 4px', 
width:'47px', 
marginTop:'3px'

  
  });

const DescriptionText = styled(Typography)({
  color: "#6B6B6B",
  fontSize: "12px",
 fontFamily:'Manrope',
 fontWeight:500,
  marginTop: "8px"
});

const TextStyle = styled(Typography)({
  color: "#6B6B6B",
  fontSize: "12px",
 fontFamily:'Manrope',
 fontWeight:500,
 marginTop:"4px"
});
const StyledIconButton = styled(IconButton)({
  padding: 0,
  marginRight: "8px",
  marginTop:'4px',
  width:'24px',
  height:'24px'
})

// Customizable Area End
