import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  Paper,
  Box,
  Typography,
  IconButton,
  Grid,
  InputAdornment,  
  Dialog,
  MenuItem,
  Select,
  Chip,
  ListItemIcon
} from "@material-ui/core";
import { ThemeProvider, createTheme, withStyles } from "@material-ui/core/styles";
import { ArrowIcon, ckeckedLogo, darkArrowIcon, darkBg, lightBg, logo, mainLogo, prfileFace, verifyLogo,lightImage } from "./assets";
import ClearIcon from '@material-ui/icons/Clear';
import * as Yup from "yup";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import Loader from "../../../components/src/Loader.web";
import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Field, Form, Formik } from "formik";
import FormikInputField from "../../../components/src/FormikInputField.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'


// Customizable Area End

// Customizable Area Start
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1
const currentDay = currentDate.getDate();
 const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'), 
  username: Yup.string()
  .matches(/^@/, 'Username must start with @').min(4, 'Username must be at least 4 characters long').required('Username is required'),
  password: Yup.string().matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,"password should contain atleast one uppercase,one lowercase and one special character"),
  bio: Yup.string(),
  date_of_birth: Yup.string()
    .matches(/^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/, 'Date must be in DD/MM/YYYY format')
    .test('is-valid-date', 'Invalid date', value => {
      if (!value) return true
      const [day, month, year] = value.split('/').map(Number);
      const date = new Date(year, month - 1, day);
      return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
    })
    .test('is-date-valid', 'Date cannot be in the future', value => {
      if (!value) return true; 
      const [day, month, year] = value.split('/').map(Number);
      const date = new Date(year, month - 1, day);  
      if (year > currentYear) {
        return false; 
      }
      if (year === currentYear && month > currentMonth) {
        return false; 
      }
      if (year === currentYear && month === currentMonth && day > currentDay) {
        return false; 
      }
      return date <= currentDate;
    }),
  Share_price:Yup.string().required('required'),
  phone: Yup.string(),
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    contrastText: "#fff",
    },
  },
  typography: {    
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
});



import CustomisableUserProfilesController, {
  Props,
  configJSON
} from "./CustomisableUserProfilesController.web";

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start  
  
   CustomMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: this.props.checked?"var(--Neutrals-Cool-gray-50, #E9E9E9)":"var(--Neutrals-Cool-gray-500, #222)",
      display: "flex",
      width: "100%",
      height: "44px",
       
       "&.MuiPaper-rounded":{
        borderRadius: "8px !important",
       },
      '&.MuiList-padding': {
        padding:"0px !important"
      },
      list: {
        paddingTop: '0px', 
        paddingBottom: '0px', 
      },
      '&:hover': {
        backgroundColor: this.props.checked?'#d3d3d3':"black",
      },
      '&.Mui-selected': {
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
      },     
      alignItems: "center",
      gap: "8px",
      justifyContent: 'space-between',
      fontWeight:600
    },
  }))(MenuItem);


  CustomMenuSelect = withStyles((theme) => ({
    root: {
      backgroundColor: this.props.checked?"var(--Neutrals-Cool-gray-50, #E9E9E9)":"var(--Neutrals-Cool-gray-500, #222)",
      '&:hover': {
        backgroundColor: this.props.checked?'#d3d3d3':"black",
      },
      '&.Mui-selected': {
        background:this.props.checked?"var(--Neutrals-Cool-gray-50, #E9E9E9)":"var(--Neutrals-Cool-gray-500, #222)",
        fontWeight:600,
        fontSize:"16px"
      },
      '&.Mui-selected:hover': {
        backgroundColor: this.props.checked?'#d3d3d3':"black"
      },
      '&.MuiList-padding': {
        padding:"0px !important"
      },
      list: {
        padding:"0px !important" 
      },
      display: "flex",
      width: "100%",
      height: "44px",
      alignItems: "center",
      gap: "8px",
      fontSize:"14px",
      justifyContent: 'space-between',
      fontWeight:500,
    },
  }))(MenuItem);


   popupRender =(classes:any)=>{
    return <Dialog data-test-id="dialog-allow-connect" onClose={() => this.setState({isModel:false})
        } 
        open={this.state.isModel} 
        PaperProps={{
          className: this.conditionFilter(!this.props.checked,classes.modelDarkStyle,classes.modelLightStyle)
        }}
        classes={{paperWidthSm:this.conditionFilter(!this.props.checked,classes.modelDarkStyle,classes.modelLightStyle)}} 
        BackdropProps={{className:this.conditionFilter(!this.props.checked,classes.backdropsStyleDark,classes.backdropsStyle)}} 
     >
       <Grid container justifyContent="center" alignItems="center" direction="column" style={{
              padding: "50px 60px",                                
        }}>
          <Loader loading={this.state.userProfileLoading}/>
          
          <IconButton style={{
               color: this.conditionFilter(this.props.checked,"black","#fff"),
               height: "44px",
               width: "40px",
               borderRadius: "8px",                     
               padding:"10px",
               marginBottom: "-55px",
               marginLeft:"-370px"
                 }} disableRipple disableFocusRipple disableTouchRipple data-test-id="backBtn2"
                 onClick={() => this.setState({isModel:false})}
                 >
                {this.conditionFilter(this.props.checked, <img src={darkArrowIcon} alt="" />,<img src={ArrowIcon} alt="" />)}
          </IconButton>
            <img src={logo} className={classes.IconsStyle} alt=""  />
         <Typography                                                       
        className={classes.modelHead}>
        We sent you a code
      </Typography>
      <Typography className={classes.welcomeTextmodel}>
         Enter it below to verify{" "}
          <span className={classes.arrowEndText}>
            {this.conditionFilter(this.state.modelType=="mobile",`+${this.state.countryCode} ${this.state.phoneNumber}.`,this.state.email)} 
          </span>
        </Typography>        
      <Box
       component="form"         
       data-test-id="Otpform"
       style={{width:"100%",margin:"16px 0px"}}
       >
        {this.state.otpError && (
            <Box className={classes.errorContainer}>
            <Typography className={classes.errorText} data-test-id='validationError'>{this.state.otpError}</Typography>
           </Box>
         )}
         <Field
               component={FormikInputField}
               name={"otp"}
               value={this.state.otpInput}
               error={this.state.otpError !==""}
               label={"OTP"}
               onChange={this.handleChangeOtp}
               InputProps={{
                style:{borderRadius:"10px"}
               }}
               placeholder={"Enter name"}
               variant="outlined"
               fullWidth
               id="otp"
             />  
        <Typography className={classes.suggestionText} data-test-id="resendOtpBtn" onClick={()=>this.handleResendOtp()}>
          Resend OTP
       </Typography>          
       </Box>
       
       <Button
          data-test-id='next'
          className={classes.button}
          fullWidth
          onClick={()=>this.verifyOtphandler()}
          endIcon={<ChevronRightIcon/>}
        >
          Next
        </Button>     

      <Box style={{display:"flex" }}>
      </Box>                      
      </Grid>
     </Dialog>
   }

   conditionFilter = (condition:any,trueStatement:any,falseStatement:any) =>{
    return condition ?  trueStatement :falseStatement
   }

    congratesModel =(classes:any)=>{
      return <Dialog data-test-id="dialog-allow-connect" onClose={() => this.setState({congratulationModel:false})
          } 
          open={this.state.congratulationModel} 
          PaperProps={{
            className: this.conditionFilter(!this.props.checked,classes.modelDarkStyle,classes.modelLightStyle)
          }}
          classes={{paperWidthSm:this.conditionFilter(!this.props.checked,classes.modelDarkStyle,classes.modelLightStyle)}} 
          BackdropProps={{className:this.conditionFilter(!this.props.checked,classes.backdropsStyleDark,classes.backdropsStyle)}} 
       >
         <Grid container justifyContent="center" alignItems="center" direction="column" style={{
                padding: "50px 60px",                                
          }}>
            <IconButton style={{
                 color: this.conditionFilter(this.props.checked,"black","#fff"),
                 height: "44px",
                 width: "40px",
                 borderRadius: "8px",                     
                 padding:"10px",
                 marginBottom: "-55px",
                 marginLeft:"-370px"
                   }} disableRipple disableFocusRipple disableTouchRipple data-test-id="backBtn2"
                   onClick={()=>this.setState({congratulationModel:false})}
                   >
                 {this.conditionFilter(this.props.checked, <img src={darkArrowIcon} alt="" />,<img src={ArrowIcon} alt="" />)}
            </IconButton>
              <img src={logo} className={classes.IconsStyle} alt=""  />
           <Typography style={{                                
            fontStyle: 'normal',
            fontWeight: 800,
            lineHeight: '32px',
            letterSpacing: '-0.414px',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',                                    
            fontFamily: 'Manrope',
            fontSize: "24px",
            width:"327px",
            textAlign: "center",                
  
        }}>
          Congratulations! 
        </Typography>
        {this.conditionFilter(this.state.modelType == "profile",
        <Typography style={{                                
                textAlign: "center" ,                
                fontSize:"24px",
                fontWeight:800            
            }} className={classes.arrowEndText}>
              Ready to send some Arrows?
            </Typography>,""
        )}
       
       { this.state.modelType == "profile"&&<Typography style={{fontWeight:600,marginBottom:"25px"}}>
         Your profile has been updated!        
        </Typography>}
        
        { this.state.modelType!=="profile"&&<Typography style={{margin:"8px 0px 25px 0px",display:"flex",flexDirection:"column",textAlign: "center",fontWeight:600}} >
        {this.conditionFilter(this.state.modelType=="mobile","You have Successfully Verified phone","You have Successfully Verified Email")} 
        <span className={classes.arrowEndText}>{ this.conditionFilter(this.state.modelType=="mobile",`+${this.state.countryCode} ${this.state.phoneNumber}.`,this.state.email)}</span>
        </Typography> }  
    
        <Box style={{display:"flex" }}>
        <Button fullWidth variant="contained" color="primary"  style={{width:"319px",padding:"15px 30px" ,color:"#fff",background:"linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)" }} 
           className={this.conditionFilter(this.props.checked,classes.buttonStyleDArk ,classes.buttonStyle)} data-test-id="navigateBtn" 
           onClick={()=>this.handleNavigation()} endIcon={<ChevronRightIcon />}>
          Go to Dashboard
          </Button>
        </Box>                      
        </Grid>
       </Dialog>
     }

     renderOwnershipField =(errors:any,touched:any,setFieldValue:any,values:any)=>{
      const {classes} = this.props
      return <Select
      IconComponent={(iconProps) => (
        <KeyboardArrowDownIcon  {...iconProps} style={{color:this.conditionFilter(this.props.checked,"balck","white")}} />
      )}
      MenuProps={{
        classes: { paper: classes.menuPaper, list: classes.menuList },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null
      }}
      name="Share_price"
      fullWidth
      error={touched.Share_price && !!errors.Share_price}
      value={this.conditionFilter(this.state.ownerShip?.id,this.state.ownerShip?.id,null)}
      onChange={(event) => this.handleSharePriceChange(event, setFieldValue)}
      variant="outlined"
      id="share_price"
      style={{
        borderColor:this.conditionFilter(values.Share_price, "#8833FF", "")
      }}
      >
      {this.state.userOwnerShip.map((val:any)=> <this.CustomMenuItem key={val.id} value={val.id}>{val.attributes.price}</this.CustomMenuItem>)} 
    </Select>
     }

    renderFormField=(touched:any,setFieldError:any)=>{
      const {classes} = this.props
      return  <Grid container spacing={4}>              
      <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
       <Field
         component={FormikInputField}
         name={"email"}
         value={this.state.email}
         InputLabelProps={{
          className: classes.label1,
         }}
         onChange={this.handleEmailChnage}
         error={ (touched.email && !this.state.personalProfile.email)|| this.state.apiError.includes("Email")|| this.state.validationError.includes("Email") }
         InputProps={{
          style: {
            borderRadius: "10px",
            borderColor: this.conditionFilter((this.state.email &&  !this.state.apiError.includes("Email")&& !this.state.validationError.includes("Email") ), "#8833FF" ,""),
          },
          endAdornment: (
            <InputAdornment position="end">
              {this.conditionFilter(this.state.isEmailVerfy, <img src={verifyLogo} alt="icon" style={{ width: '24px', height: '24px' }} />
              ,<Button onClick={()=>this.hanadleEmailVerfy(setFieldError)} className={classes.verifyBtnStyle}>Verify</Button>)}
            </InputAdornment>
          ),
          inputProps: {
            onChange: (e:{target:{value:string}}) => {
              e.target.value = e.target.value.toLowerCase(); 
            }
          }
        }}            

         label={"Email*"}
         placeholder={"Enter email"}
         variant="outlined"
         fullWidth
         id="email"       
         data-test-id="email"          
       />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", rowGap:"20px",columnGap:"8px"}}> 
      <Box >
      <Typography className={classes.label} style={{marginTop:"0px"}}>
       Phone
      </Typography>
      <Select
        IconComponent={(iconProps) => (
          <KeyboardArrowDownIcon  {...iconProps} 
          style={{color:this.conditionFilter(this.props.checked,"balck","white"),right:"0px",top:"16%"}}
          />
        )}
        MenuProps={{
          classes: { paper: classes.menuPaper, list: classes.menuList},
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          onEntering: (element) => {
            const firstItem = element.querySelector('li');
            if (firstItem) {
              firstItem.blur();
            }
          },
        }}
        
        value={`+${this.state.countryCode}`}
        onChange={this.handleSelectCountryCode}
        name="contryCode"               
        inputProps={{
          classes: {
            nativeInput:this.conditionFilter(this.props.checked,classes.nativeInputLight,classes.nativeInput),
          },
        }}
        style={{
          borderColor:"8833FF",
        }}
        variant="outlined"
        id="contry_code"
        >
        {this.state.codesFilter.map((val:any)=>
           <this.CustomMenuItem key={val.isoCode2} value={val.countryCodes}>
           <div className={classes.contryStyle}> 
            <Typography className={classes.contryFont} > +{val.countryCodes} </Typography >
           <Typography  className={classes.contryFont}> {val?.country} </Typography ></div>
            <img
               width={36}
               height={18}
               src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${val.isoCode2}.svg`}
             />
            </this.CustomMenuItem>)} 
      </Select>
      </Box>
        

     <Field
       component={FormikInputField}
       name={"phone"}
       InputLabelProps={{
        className: classes.label2,
       }}
       error={this.state.apiError.includes("Number")||this.state.validationError.includes("Number") }
       value={this.state.phoneNumber}
       onChange={this.handleMobileChnage}
       InputProps={{
        style: { 
          borderRadius: "10px",
          borderColor: this.conditionFilter(this.state.phoneNumber && !this.state.apiError.includes("Number")&& !this.state.validationError.includes("Number"), "#8833FF" , ""),
         },
        endAdornment: (
          <InputAdornment position="end">
           {this.conditionFilter(this.state.ismobileVerfy, <img src={verifyLogo} alt="icon" style={{ width: '24px', height: '24px' }} />
            ,<Button onClick={this.hanadleMobileVerfy} id="modileVerify" className={classes.verifyBtnStyle}>Verify</Button>)}
          </InputAdornment>
        ),
      }}               
       label={"Phone"}
       placeholder={"Enter mobile number"}
       variant="outlined"
       fullWidth
      data-test-id="phone"
    />
  </Grid>
     </Grid> 
     }
     showCroppedImage=(classes:{ [key: string]: string })=>{
      return(this.state.isImage? <></>:<Box component='label'>
           <Box className={this.props.checked ? classes.divLight : classes.div}></Box>
           <input type='file' accept="image/jpeg, image/png" hidden onChange={this.handleChangePicture} data-test-id='uploadImage' />
           {this.state.userImage? 
           <img src={this.state.userImage} className={classes.profileImage}/>: 
           <img src={this.conditionFilter(this.props.checked,lightImage,prfileFace)} className={classes.profileImage} />}
           <Box className={classes.picContainer}>
             <PhotoCameraOutlinedIcon className={this.conditionFilter(this.props.checked,classes.iconColor,classes.iconColorDark)} />
             <Typography className={this.conditionFilter(this.props.checked,classes.uploadText,classes.uploadTextDark)} data-test-id='uploadText'>
             Edit picture
             </Typography>
           </Box>
         </Box>)

     }   
   
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {classes} = this.props
    const {personalProfile ,selectedCategories,categories,selectedSubCategories} = this.state 
    console.log(this.state.selectedsubScriptions,"this.state")

    return (
      <ThemeProvider theme={this.conditionFilter(this.props.checked , lightTheme,darkTheme)}>
        <Paper className={this.conditionFilter(this.props.checked ,classes.thinColor , classes.color)}>
        <Loader loading={this.state.userProfileLoading} />
        <Box className={this.conditionFilter(this.props.checked ,classes.thinBackground,classes.imageBackground )}>
        <Button className={classes.logoutBtn} 
          data-test-id="logoutBtn"
          style={{color:this.conditionFilter(this.props.checked,"black","white")}}
          endIcon={<ChevronRightIcon style={{marginTop:"3px",width:"24px",height:"24px"}}/>}
          onClick={this.handleLogout}
        > Logout</Button>
        <Container maxWidth='xl' className={ this.conditionFilter(this.props.checked ,classes.chieldLightStyle, classes.chieldDarkStyle )} >
          
          <Box className={classes.headerStyle}>

          <Box>
            <IconButton 
            className={this.conditionFilter(this.props.checked,classes.backIconLightTheme,classes.backIcon)}         
            disableRipple disableFocusRipple disableTouchRipple  data-test-id="backBtn" 
            onClick={()=>this.handlePreviousNavigation()}
         >
                 {this.conditionFilter(this.props.checked, <img src={darkArrowIcon} alt="" />,<img src={ArrowIcon} alt="" />)}
            </IconButton>
         </Box>

         <Box className={classes.logoOuter}>
          <img src={mainLogo} className={classes.mainLogo}    alt="" />
          <Typography style={styles.welcomeText}>
                      {configJSON.welocomeMesage} {""}<span className={classes.arrowText}>{configJSON.arrowsText}</span>
           </Typography>
         </Box>

         <Box></Box>
         </Box>
        
        

       <Box className={!this.state.isImage?classes.imgContainer:classes.imgContainerHide} >
              {this.state.croppedImage && !this.state.isImage ? 
             <Box component='label'>
             <Box className={this.props.checked ? classes.divLight : classes.div}></Box>
             <input type='file' accept="image/jpeg, image/png" hidden onChange={this.handleChangePicture} data-test-id='uploadImage' />
              <img 
              src={this.state.croppedImage === '' ? this.state.userImage : this.state.croppedImage}
              className={classes.profileImage}/>
             <Box className={classes.picContainer}>
               <PhotoCameraOutlinedIcon className={this.conditionFilter(this.props.checked,classes.iconColor,classes.iconColorDark)} />
               <Typography className={this.conditionFilter(this.props.checked,classes.uploadText,classes.uploadTextDark)} data-test-id='uploadText'>
               Edit picture
               </Typography>
             </Box>
           </Box>:this.showCroppedImage(classes)
          }
        </Box>
        {this.state.isImage && <Box className={classes.cropContainer}>
  <ReactCrop data-test-id='reactCrop' circularCrop aspect={1} crop={this.state.crop}  onChange={this.updateCroppedImage} onComplete={this.handleCropComplete}>
  
    <img data-test-id='loadImage'
    ref={this.imageRef} onLoad={this.onImageLoad}  src={this.state.userImage}
    />
  </ReactCrop>
  <Button data-test-id='cropBtn' className={classes.cropButton} onClick={this.closeDragImage}>{configJSON.done}</Button>
  </Box> }
        <Box>
          <Formik
           initialValues={{
            email:"",
            name:personalProfile?.full_name ||"",
            password: personalProfile.password||"",
            username:personalProfile.user_name ||"",
            bio:personalProfile.bio||"",
            date_of_birth: moment(personalProfile.date_of_birth).format('DD/MM/YYYY')||"",
            phone:"",
            Share_price:this.state.selectedOwnerShip?.attributes?.price||""
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          data-test-id="login-formik-form"
        >
        {(formikProps) => {
          const { values ,touched,errors,setFieldError,setFieldValue} = formikProps;
          return (
            <Form noValidate autoComplete="off">              
              {this.conditionFilter(this.state.apiError, 
                <Box className={classes.errorContainer}>
                     <Typography className={classes.errorText} data-test-id='apiError'>{this.state.apiError}</Typography>
                </Box>,""
         )}

                {this.conditionFilter(this.state.validationError,
                      <Box className={classes.errorContainer}>
                       <Typography className={classes.errorText} data-test-id='validationError'>{this.state.validationError}</Typography>
                      </Box>,""
                )}
                  
              <Grid item xs={12}>
               <Typography  align="left" className={classes.sectionHead}>
                  Personal details
                </Typography>
              </Grid>
             <Grid container spacing={4}>             
              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", columnGap:"20px",marginTop:"10px"}}>
               <Field
                 component={FormikInputField}
                 name={"name"}
                 value={values.name}
                 label={"Name*"}
                 error={touched.name && !!errors.name}
                 InputProps={{
                  style: {
                    borderRadius: "10px",
                    borderColor: this.conditionFilter((values.name && !errors.name) , "#8833FF" , ""),
                  },
                }}              
                 inputProps={{
                  maxLength: 20, 
                }}
                 InputLabelProps={{
                  className: classes.label1,
                 }}

                 placeholder={"Enter name"}
                 variant="outlined"
                 fullWidth
                 id="name"
               />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px",marginTop:"10px"}}>
             <Field
               component={FormikInputField}
               name={"username"}
               value={values.username}
               error={touched.username && !!errors.username}
               InputProps={{
                style: {
                  borderRadius: "10px",
                  borderColor: this.conditionFilter((values.username && !errors.username),"#8833FF" , ""),
                },
              }}
               InputLabelProps={{
                className: classes.label1,
               }}
               autoComplete="off"
               label={"User name*"}
               placeholder={"Enter username (must start with @)"}
               variant="outlined"
               fullWidth
              id="username"
            />
              </Grid>
             </Grid>

             {this.renderFormField(touched,setFieldError)}

             <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
               <Field
                 component={FormikInputField}
                 name={"date_of_birth"}
                 InputLabelProps={{
                  className: classes.label1,
                 }}
                 placeholder='DD/MM/YYY'
                 type="string"
                 value={values.date_of_birth}

                 error={touched.date_of_birth && !!errors.date_of_birth}
                 InputProps={{
                  style:{
                    borderRadius:"10px",
                    borderColor: this.conditionFilter((values.date_of_birth && !errors.date_of_birth ), "#8833FF" , ""),
                  }
                 }}
                 className={classes.inputStyle}
                 label={"Date of birth*"}
                 variant="outlined"
                 fullWidth
                 id="Date_of_birth"
               />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
             <Field
               component={FormikInputField}
               name={"password"}
               id="passwordInput"
               value={values.password}
               InputLabelProps={{
                className: classes.label1,
               }}
              type={this.conditionFilter(this.state.isPasswordVisible , "text" , "password")}
               InputProps={{
                style:{
                  borderRadius:"10px",
                  borderColor: this.conditionFilter((values.password && !errors.password) ,"#8833FF" , ""),
                },
                 endAdornment: (
                   <InputAdornment
                     position="end"
                     onClick={this.toggleHnadle}
                     >
                     <IconButton style={{color:"#6B6B6B"}}>
                       {this.state.isPasswordVisible ? (
                         <VisibilityOffIcon />
                       ) : (
                         <VisibilityIcon />
                       )}
                     </IconButton>
                   </InputAdornment>
                 ),
               }}
               autoComplete="off"
               error={touched.password && !!errors.password}
               label={"Password"}
               placeholder={"Enter password"}
               variant="outlined"
               fullWidth
            />
          </Grid>
             </Grid>

              <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={12} lg={12} >
              <Field
               component={FormikInputField}
               name={"bio"}
               InputLabelProps={{
                className: classes.label1,
               }}
               InputProps={{
                style:{
                  borderRadius:"10px",
                  borderColor:this.conditionFilter(( values.bio && !errors.bio), "#8833FF" , "")
                }
               }}
               value={values.bio}
               multiline
               rows={3}
               label={"Bio"}
               placeholder={"Enter your bio here"}
               variant="outlined"
               fullWidth
              id="Bio"
            />               
            </Grid>              
             </Grid> 
             

             <Grid item xs={12}>
                <Typography  align="left" className={classes.sectionHead}>
                   Social media handles
                </Typography>
              </Grid>
             <Grid container spacing={4} >
             
              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px",marginTop:"10px"}}>
               <Field
                 component={FormikInputField}
                 name={"x"}
                 value={""}
                 InputLabelProps={{
                  className: classes.label1,
                 }}
                 label={"Twitter (X)"}
                 InputProps={{
                  style:{borderRadius:"10px"}
                 }}

                 placeholder={"Add Account"}
                 variant="outlined"
                 fullWidth
                 id="name"
               />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px", marginTop:"10px"}}>
             <Field
               component={FormikInputField}
               name={"instagram"}
               InputLabelProps={{
                className: classes.label1,
               }}
               value={""}
               InputProps={{
                style:{borderRadius:"10px"}
               }}
               label={"Instagram"}
               placeholder={"Add Account"}
               variant="outlined"
               fullWidth
              id="instagram"
            />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
             <Field
               component={FormikInputField}
               name={"Facebook"}
               value={""}
               InputLabelProps={{
                className: classes.label1,
               }}
               InputProps={{
                style:{borderRadius:"10px"}
               }}
               label={"Facebook"}
               placeholder={"Add Account"}
               variant="outlined"
               fullWidth
              id="Facebook"
            />
          </Grid>
             </Grid> 

             <Grid item xs={12}>
                <Typography  align="left" className={classes.sectionHead}>
                Categories
                </Typography>
              </Grid>
            <Grid container spacing={4} >             
              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
              <Box width={"100%"}>
              <Typography className={classes.label}>
                Category
              </Typography>
              <Box>
               <Select
                IconComponent={(iconProps) => (
                  <KeyboardArrowDownIcon  {...iconProps} style={{color:this.conditionFilter(this.props.checked,"balck","white")}} />
                )}
                name="category"
                fullWidth
                variant="outlined"
                value={selectedCategories.map((category:any) => category.attributes.id)}
                onChange={this.handleChangeCategories}                
                MenuProps={{
                  classes: { paper: classes.menuPaper, list: classes.menuList },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null
                }}
                renderValue={() => "Add Category"}
                multiple={true}
                displayEmpty
                id="category"
                style={{
                  borderColor:this.conditionFilter(selectedCategories.length > 0, "#8833FF", "")
                }}
                >
                {categories.map(((val:any) => <this.CustomMenuSelect key={val.id} value={val.attributes.id}>
                  {val.attributes.name}
                  <ListItemIcon style={{display:"flex",justifyContent:"end"}}>                    
                    {this.conditionFilter(val.attributes.is_selected,<img src={ckeckedLogo} alt="" />,<span 
                    style={{width:"20px" ,height:"20px",border:"1px solid #6B6B6B",borderRadius:"50%"}}></span>)} 
                </ListItemIcon>
                </this.CustomMenuSelect>))}  
                 
              </Select>
              <Box className={classes.chipStyle}>
                 {selectedCategories.map((val:any)=>{                
                    return <Chip label={val.attributes.name}
                     className={classes.chipStyleInner} 
                     onDelete={()=>this.handleDeleteCategories(val.id) }
                     data-test-id="chipId"
                     deleteIcon={<ClearIcon style={{color:"white",width:"12px"}}
                      />}/>}
                  )}
              </Box>
               </Box>
              </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
              <Box width={"100%"}>
                <Typography className={classes.label}>
                SubCategory
                 </Typography>
                 <Box>
                <Select
                IconComponent={(iconProps) => (
                  <KeyboardArrowDownIcon  {...iconProps} style={{color:this.conditionFilter(this.props.checked,"balck","white")}} />
                )}
                MenuProps={{
                  classes: { paper: classes.menuPaper, list: classes.menuList },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null
                }}
                name="subCategory"
                fullWidth
                label="Sub-Category"
                variant="outlined"
                id="subCategory"
                multiple={true}
                renderValue={() => "Add Sub-Category"} 
                displayEmpty
                style={{
                  borderColor:this.conditionFilter(selectedSubCategories.length > 0, "#8833FF", "")
                }}
                value={selectedSubCategories.map((category:any) => category.id)}
                onChange={this.handleChangeSubCategories}
                >

              {categories.filter((category:any) => category.attributes.is_selected).map((category:any) => (
                  <React.Fragment key={category.id}>
                      <this.CustomMenuSelect key={category.id} >
                          <Typography variant="subtitle1" className={classes.colorFullText}>{category.attributes.name}</Typography>
                      </this.CustomMenuSelect>
                      {category.attributes.sub_categories.map((subCategory:any) => (
                                <this.CustomMenuSelect style={{fontSize:this.conditionFilter(subCategory.is_selected,"16px","14px"), fontWeight:this.conditionFilter(subCategory.is_selected,"600","500")}}  key={subCategory.id} onClick={()=> this.handleChangeSubCategories(subCategory.id)} value={subCategory.id}>
                                  {subCategory.name}              
                                  <ListItemIcon style={{display:"flex",justifyContent:"end"}}>                    
                                  {this.conditionFilter(subCategory.is_selected,<img src={ckeckedLogo} alt="" />,<span style={{width:"20px" ,height:"20px",border:"1px solid #6B6B6B",borderRadius:"50%"}}></span>)} 
                              </ListItemIcon>
                                </this.CustomMenuSelect>
                        ))}
                  </React.Fragment>
              ))}
              </Select>
              <Box className={classes.chipStyle}>
                {selectedSubCategories.map((subCategory:any) => (
                <Chip
                 key={subCategory.id}
                 label={subCategory.name}
                 data-test-id="chipId2"
                 className={classes.chipStyleInner}
                 onDelete={() => this.handleDeleteSubCategories(subCategory.id)}
                 deleteIcon={<ClearIcon style={{color:"white",width:"12px"}} />}
                />
            ))}
                
            </Box>
              </Box>
              </Box>
              </Grid>

           </Grid>  

           <Grid item xs={12}>
                <Typography  align="left" className={classes.sectionHead}>
                 Become an Owner
                </Typography>
              </Grid>
            <Grid container spacing={4} >             
              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
              <Box width={"100%"}>
              <Typography className={classes.label}>
                Share Price*
              </Typography>
               {this.renderOwnershipField(errors,touched,setFieldValue,values)}
              </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} style={{display:"flex", gap:"20px"}}>
             <Box width={"100%"}>
              <Typography className={classes.label}>
              Subscription
              </Typography>                            
              <Select
               IconComponent={(iconProps) => (
                <KeyboardArrowDownIcon  {...iconProps} style={{color:this.conditionFilter(this.props.checked,"balck","white")}} />
              )}
                MenuProps={{
                  classes: { paper: classes.menuPaper, list: classes.menuList },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null
                }}
                name="Subscription"
                fullWidth
                onChange={this.handleSubscriptionChange}
                value={this.conditionFilter(this.state.selectedsubScriptions?.id,this.state.selectedsubScriptions?.id,null)}
                variant="outlined"
                id="Subscription "
                style={{
                  borderColor:this.conditionFilter(this.state.selectedsubScriptions, "#8833FF", "")
                }}
                >
               {this.state.subScriptions.map((val:any)=> <this.CustomMenuItem key={val.id} value={val.id}>{val.attributes.name}</this.CustomMenuItem>)}
                 
               </Select>
               </Box>
              </Grid>

           </Grid>  
           <Button className={classes.submitBtnStyle}   endIcon={<ChevronRightIcon/>} type="submit">Save</Button>
          {this.popupRender(classes)}
          {this.congratesModel(classes)}


            </Form>
            );
          }}
          </Formik>
        </Box>
          
          
        </Container>
        </Box>
        </Paper>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  color:{
    backgroundColor:'#070707'
  },
  chipStyle:{
    display: "block",  
    alignItems: "center" as "center",  
    borderRadius: "16px",
    width:"100%",
    marginTop:"12px",
    gap:"4px"  
  },
  chipStyleInner:{    
    fontWeight:500,
    padding: "4px 6px 4px 8px",
    fontSize:"10px",
    color:"white",
    margin: "0px 4px 4px 4px",
    fontFamily: "Manrope",
    background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },
  backIcon: {
    height: "40px",
    width: "40px",
    background:"var(--Basic-Black, #222)",
    borderRadius:"8px",
    color: '#fff',
    fontSize: '30px',
    padding:"10px"   
  },
  menuPaper: {
    marginTop: '8px', 
    borderRadius: '8px !important',
    background: "none !important",
  },
  submitBtnStyle:{
    height: "48px",
    padding: "15px 30px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    display: "flex",
    width: "160px",     
    borderRadius: "10px",
    marginTop:"42px",
    color:"white"
  },
  menuList: {
    paddingTop: '0px', 
    paddingBottom: '0px', 
    maxHeight:"364px"
  },
  inputStyle:{
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      filter: "invert(45%) sepia(2%) saturate(16%) hue-rotate(314deg) brightness(92%) contrast(98%)"
    }
  },
  label: {
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: "left" as "left",
    marginBottom:'8px',
    fontFamily: "Manrope",
    fontSize: "14px",    
    marginTop:'10px',
  },
  label1:{
    top: -5,
    left:0,
    position: "absolute" as "absolute",
  },
  label2:{
    top: -5,
    left:0,
    position: "absolute" as "absolute",
    opacity:0
  },
  contryStyle:{
    display:"flex",
    gap:"8px"
  },
  contryFont:{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 500,
  }, 
  button: {
    height:'46px',
    borderRadius: "10px",
    color: "#FFF",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    marginTop: '25px',
    background: "linear-gradient(134.78deg, #BD02F7 19.96%, #3858E3 109.45%)",
    textTransform: "capitalize" as "capitalize",  
  },
  suggestionText: {    
    textAlign: "left" as "left",
    textShadow:"0px 5.556px 11.111px rgba(0, 0, 0, 0.03), 0px 11.111px 44.444px rgba(0, 0, 0, 0.06)",
    fontFamily: "Manrope",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop: "10px",
    cursor:'pointer',
    fontSize: "14px",
    fontStyle: "normal" as'normal',
    fontWeight: 500,  
    textDecoration:"underline",
    textDecorationColor:"rgba(142, 56, 227)" 
  },
  div: {
    width: '132.5px',
    background: "rgba(0,0,0,0.5)",
    borderRadius: '50%',
    position: 'absolute' as 'absolute',
    top: '2.8%',
    left: '2.8%',
    height: '132px',
  },
  divLight: {
    position: 'absolute' as 'absolute',
    top: '2.8%',
    left: '2.8%',
    height: '132.5px',
    width: '132.5px',
    background: "rgba(255,255,255,0.5)",
    borderRadius: '50%',
  },
  divLight1:{
    top: '2.8%',
    height: '132.5px',
    width: '1px',
    background: "rgba(255,255,255,0.5)",
    borderRadius: '50%',
  },
  div1: {
    width: '132px',    
    height: '132.5px',
    background: "rgba(0,0,0,0.5)",
    borderRadius: '50%',
    top: '2.8%',
  },
  imageDivLight:{
    top: '2.8%',
    height: '132px',
    width: '132px',
    position: 'absolute' as 'absolute',    
    background: "rgba(255,255,255,0.5)",
    borderRadius: '50%',
  },
  Imagediv:{   
    background: "rgba(0,0,0,0.5)",
    borderRadius: '50%',
    position: 'absolute' as 'absolute',
    top: '2.8%',
    height: '132px',
    width: '132px',
  },
  verifyBtnStyle:{
    textTransform: "capitalize" as "capitalize",
    borderRadius:"5px",
    padding: "6px 10px",    
    background:"linear-gradient(134.78deg, #6EE7B7 19.96%, #059669 109.45%)",
    color:"white",
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '6EE7B7',
    },
    display: "flex",
    justifyContent:"center",
    alignItems: "center",
    height: "24px",

  }, 
  IconsStyle:{
    margin:"16px",
    width: "54px",
    height: "53px", 
  },
  backdropsStyle:{
    background:"rgba(255, 255, 255, 0.9)" 
  },
  backdropsStyleDark:{
    background: "rgba(8,4,8,0.6) !important" 
  },
  buttonStyle: { 
    marginTop: '8px',
    padding:"16px", 
    boxShadow:"none !important" ,
    background: "linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border :"1px solid transparent",
    borderRadius: "10px",         
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,    
    textTransform: "capitalize" as "capitalize",
    color: "#FFF",
    fontFamily: "Manrope"        
},
buttonStyleDArk: {  
fontWeight: 700,
  marginTop: '8px',
  padding:"16px", 
  borderRadius: "10px",
  textTransform: "capitalize" as "capitalize",
  color: "black",
  boxShadow:"none !important",
  background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)",
  backgroundClip: "padding-box, border-box",
  backgroundOrigin: "padding-box, border-box",
  border :"1px solid transparent",     
  fontFamily: "Manrope",
  fontSize: "14px",
  fontStyle: "normal" as 'normal',           
},
modelHead:{
 fontStyle: 'normal'as 'normal',
  fontWeight: 800,
  lineHeight: '32px',
  fontFamily: 'Manrope',
  fontSize: "32px",
  width:"327px",
  textAlign: "center" as "center",
  marginBottom:"8px" ,
  background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  WebkitTextFillColor: 'transparent', 
  letterSpacing: '-0.414px',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',                                   
},
colorFullText:{
  WebkitTextFillColor: 'transparent', 
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text', 
  fontWeight: 600,
  background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
},
welcomeTextmodel:{
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal" as 'normal',
    fontWeight: 600,
    lineHeight: `32px`,
    letterSpacing:` -0.155px`,                                
    textAlign: "center" as "center"    
},
  arrowEndText: {   
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
  WebkitBackgroundClip: 'text',                       
  },
  modelDarkStyle:{ 
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    opacity: "0.88",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #191919 ,#505050)",
    boxShadow:"none"        
  },
  modelLightStyle:{
    borderRadius: "10px", 
    boxShadow:"none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #dfd6d6 ,#505050)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",  
    opacity: "0.82",
    backdropFilter: "blur(1 px)",    
   },
  backIconLightTheme: {
    color: '#222222',
    fontSize: '30px',
    height: "40px",
    width: "40px",
    background:"var(--Neutrals-Cool-gray-50, #E9E9E9)",
    borderRadius:"8px",
    padding:"10px"
  },
  errorContainer:{
    alignItems: 'center',
    borderRadius: "0.625rem",
    borderLeft: '1.3px solid var(--Status-Red-600, #DC2626)',
    background: 'linear-gradient(92deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0.00) 28.72%), rgba(33, 33, 33, 0.29)',
    backdropFilter: 'blur(24.5px)',
    color: '#DC2626',
    fontFamily: 'Manrope',
    fontSize: "10px",
    display: 'flex',
    padding: '0.9rem 1rem',    
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    marginBottom: "1.2rem"
  },
  errorText:{
    fontSize: "10px",
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#DC2626',
    fontFamily: 'Manrope',
},
  formBox: {
    width: '80%',
  },
  headerStyle:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"

  },
  nativeInputLight: {
    opacity: '1 !important', 
    position: "absolute" as "absolute",
    color: 'black', 
    fontFamily:'Manrope',
    bottom:"11 !important",
    backgroundColor: 'transparent !important', 
    border:"none !important" as "none",
    width:"44px",
    textAlign:"center" as "center" , 
    "@media (max-width: 1280px)": {
      bottom:"7 !important",
    },
    "@media (max-width: 960px)": {
      bottom:"5 !important",
    }
  },
  nativeInput: {
    opacity: '1 !important', 
    position: "absolute" as "absolute", 
    color: 'white', 
    fontFamily:'Manrope',
    bottom:"11 !important",
    backgroundColor: 'transparent !important', 
    border:"none !important" as "none",
    width:"44px",
    textAlign:"center" as "center",     
    "@media (max-width: 1280px)": {
      bottom:"7 !important",
    },
    "@media (max-width: 960px)": {
      bottom:"5 !important",
    }
  },
  logoutBtn:{
    position:"absolute" as "absolute",
    top:"11px",
    right:"144px",
    background:"none !important",
    fontSize:"14px",
    fontWeight:700,
    padding:"0 px",
    cursor:"pointer"

  },
  logoOuter:{
   alignItems: "center",
   display: "flex",
   flexDirection: "column" as "column",
   gap:"15px"
  },
  thinColor: {
    backgroundColor: '#fff'
  },
  imageBackground: {
    backgroundImage: `url(${darkBg})`,
    padding:"130px 0",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",    
    backgroundAttachment: "scroll",
    display:"flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    width: "100%",
    overflow: "auto",    
     alignItems: "center",
  },
  thinBackground: {
    backgroundImage: `url(${lightBg})`,
    width: "100%",
    overflow: "auto",
    flexDirection: "column" as "column",
    justifyContent: "center",
     alignItems: "center",
    backgroundRepeat: "no-repeat",    
    backgroundAttachment: "scroll",
    padding:"130px 0",
    backgroundSize: "100% 100%",
    display:"flex",   
  },
  picContainer:{
    top:'35%',
    left:'3%',
    width:'100%',
    display:'flex',
    position:'absolute' as 'absolute',   
    flexDirection:'column' as 'column' ,
    justifyContent:'center',
    alignItems:'center'
  },
  imgContainer:{
    height:'132px',
    width:'132px',
    marginTop:'22px',
    position:'relative' as 'relative',
    marginBottom:'22px',
  },
  imgContainerHide:{
   display:'none'
  },
  profileImage:{    
    borderRadius: '50%',
    border: '4px solid #8833FF',
    height: '132px',
    width: '132px',
    objectFit:"fill" as "fill"
  },
  imageOuter:{
    backgroundOrigin: "padding-box, border-box",   
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 ,#BA05F7)",
    border: "4px solid transparent",
    borderRadius:'50%',
    height:'133px',
    width:'133px',
    backgroundClip: "padding-box, border-box",
  },
  photoIcon:{
  cursor:'pointer',  
  fontSize:'20px',
},
labelWrapper:{
  display: 'block',
  width: '100%',
  height: '100%',
  cursor: 'pointer'
},
picCloseContainer:{
  position: 'absolute' as 'absolute',
  top: '0%',
  left: '0%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row' as 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height:"100%",
  },
   iconColor: {
    fontSize:'20px',
    cursor: "pointer",
    color:"black"    
  },
  iconColorDark:{
    fontSize:'20px',
    cursor: "pointer",
    color:"white"    
  },
  uploadText:{
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    fontFamily: "Manrope",
    fontSize: "14px",
    color:"black"
  },
  uploadTextDark:{
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    fontFamily: "Manrope",
    fontSize: "14px",
    color:"white"
  },
  chieldLightStyle:{
    background: "linear-gradient(to right, #FAF9FB, #FAF9FB),linear-gradient(to right, #dfd6d6 ,#5B5B5B)",
    width:"100%",
    borderRadius: "10px",
    border: "1px solid transparent",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    opacity: "0.86",
    backdropFilter: "blur(24.5px)",
    boxShadow:"none",
    padding:"80px",
    maxWidth:"1040px",
    height:"auto",    
  },
  chieldDarkStyle:{    
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #120E17, #120E17),linear-gradient(to right, #191919 ,#505050)",
    width:"100%",
    maxWidth:"1040px",    
    height:"auto",
    opacity: "0.86",          
    borderRadius: "10px",
    backdropFilter: "blur(24.5px)",
    boxShadow:"none",
    padding:"80px"
  },
  mainLogo:{
    width:"110px",
    height:"107px"

  },
  welcomeText:{   
    fontWeight: 800,
    lineHeight: '130%',
    textShadow: '0px 3.453px 6.906px rgba(0, 0, 0, 0.03), 0px 6.906px 27.624px rgba(0, 0, 0, 0.06)',
    fontFamily: 'Manrope',
    fontSize: "24px",
    fontStyle: 'normal',
    letterSpacing: '-0.414px',
    marginBottom: "0.5rem",
    display: "flex",
    gap: "15px",    
  },
  arrowText:{    
    fontSize: "31px",
    fontStyle: 'normal',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontWeight: 800,
    lineHeight: '132px%',
    letterSpacing: '-0.414px',
  },
  sectionHead:{    
    fontSize: "18",
    fontStyle: 'normal',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontWeight: 800,
    lineHeight: '120%',
    letterSpacing: '-0.414px',
    marginTop:"30px",
  },
  cropContainer:{
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'flex-start'
  },
  cropButton:{
    width:'81px',
     height:'24px',
     borderRadius:'5px',
     textTransform:'capitalize' as 'capitalize',
     background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
     fontFamily: "Manrope",
     fontSize: "12px",
     fontWeight: 700,
     lineHeight: "14.4px",
     paddingTop:'6px',
     paddingBottom:'6px',
     paddingLeft:'10px',
     paddingRight:'10px',
     marginTop:"10px",
     color:'white'
  },
 
}
export const UserProfile = withStyles(styles)(CustomisableUserProfiles);
// Customizable Area End
